<template>
  <div class="bg-white">
    <div
      class="fixed inset-0 z-40 flex lg:hidden"
      :class="navParentIsHidden ? 'hidden' : ''"
      role="dialog"
      aria-modal="true"
    >
      <transition
        enter-active-class="transition-opacity ease-linear duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity ease-linear duration-300"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
        @after-leave="navParentIsHidden = true"
      >
        <div v-if="!navIsHidden" class="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true" />
      </transition>

      <transition
        enter-active-class="transition ease-in-out duration-300 transform"
        enter-class="-translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transition ease-in-out duration-300 transform"
        leave-class="translate-x-0"
        leave-to-class="-translate-x-full"
      >
        <div
          v-if="!navIsHidden"
          class="relative flex w-full max-w-xs flex-col divide-y divide-gray-200 overflow-y-auto bg-white pb-12 shadow-xl"
        >
          <div class="flex h-16 justify-end px-4">
            <button
              type="button"
              class="-m-2 inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200"
              @click="closeNav()"
            >
              <i-heroicons-solid:x class="h-8 w-8" />
            </button>
          </div>
          <div class="space-y-6 px-4 py-6 text-xl uppercase text-on-brand">
            <router-link
              to="/"
              class="-m-2 block p-2"
            >
              home
            </router-link>
            <router-link
              to="/about"
              class="-m-2 block p-2"
            >
              about
            </router-link>
            <router-link
              to="/recipes"
              class="-m-2 block p-2"
            >
              recipes
            </router-link>
            <router-link
              to="/stockists"
              class="-m-2 block p-2"
            >
              stockists
            </router-link>
          </div>
        </div>
      </transition>
    </div>

    <header class="relative z-10">
      <nav aria-label="Top">
        <div
          class="bg-brand-500"
        >
          <div class="mx-auto max-w-7xl px-4">
            <div class="flex h-16 items-center justify-end">
              <div class="hidden h-full lg:flex">
                <!-- Mega menus -->
                <div class="ml-8">
                  <div class="flex h-full justify-center text-xl uppercase text-white">
                    <router-link
                      to="/"
                      class="flex items-center px-4 hover:bg-brand-600"
                    >
                      home
                    </router-link>
                    <router-link
                      to="/about"
                      class="flex items-center px-4 hover:bg-brand-600"
                    >
                      about
                    </router-link>
                    <router-link
                      to="/recipes"
                      class="flex items-center px-4 hover:bg-brand-600"
                    >
                      recipes
                    </router-link>
                    <router-link
                      to="/stockists"
                      class="flex items-center px-4 hover:bg-brand-600"
                    >
                      stockists
                    </router-link>
                  </div>
                </div>
              </div>

              <!-- Mobile menu and search (lg-) -->
              <div class="flex flex-1 items-center lg:hidden">
                <!-- Mobile menu toggle, controls the 'mobileMenuOpen' state. -->
                <button
                  type="button"
                  class="-ml-2 rounded-md p-2 text-white"
                  @click="openNav()"
                >
                  <span class="sr-only">Open menu</span>
                  <i-heroicons-solid:menu class="h-8 w-8" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <router-link to="/">
          <div class="flex justify-center pt-6">
            <img class="h-48 w-auto" src="/src/assets/logo.svg">
          </div>
        </router-link>
      </nav>
    </header>
  </div>
</template>

<script setup lang="ts">
const navIsHidden = ref(true)
const navParentIsHidden = ref(true)
const openNav = () => {
  navIsHidden.value = false
  navParentIsHidden.value = false
}
const closeNav = () => {
  navIsHidden.value = true
}
const router = useRouter()
router.beforeEach(() => {
  closeNav()
})
</script>
