<template>
  <footer aria-labelledby="footer-heading" class="bg-brand-500">
    <div id="footer-heading" class="sr-only">
      Footer
    </div>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="pt-10 text-center">
        <p class="text-xl text-white">
          &copy; Creative Mass PTY LTD {{ year }}
        </p>
      </div>
    </div>
    <div class="flex justify-center gap-2 pb-10 pt-2">
      <a v-if="domain.facebook_link" target="_blank" :href="domain.facebook_link" class="flex size-10 items-center justify-center rounded-md bg-brand-50 text-on-brand">
        <i-fa:facebook />
      </a>
      <a v-if="domain.twitter_link" target="_blank" :href="domain.twitter_link" class="flex size-10 items-center justify-center rounded-md bg-brand-50 text-on-brand">
        <i-fa:twitter />
      </a>
      <a v-if="domain.instagram_link" target="_blank" :href="domain.instagram_link" class="flex size-10 items-center justify-center rounded-md bg-brand-50 text-on-brand">
        <i-fa:instagram />
      </a>
      <a v-if="domain.pinterest_link" target="_blank" :href="domain.pinterest_link" class="flex size-10 items-center justify-center rounded-md bg-brand-50 text-on-brand">
        <i-fa:pinterest />
      </a>
    </div>
  </footer>
</template>

<script setup lang="ts">
const year = ref(new Date().getFullYear())
const domain = usePagesStore().currentDomain
</script>
